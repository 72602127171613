
import type { ElTable } from 'element-plus'
import PmrMateriel from "./PmrMateriel.vue"
import { defineComponent, computed,onMounted,ref} from "vue";
import {useEntGeos} from "@/composables/useEntGeos";
import {useBande} from "@/composables/useBande";
import _ from 'lodash';

export default defineComponent({
  name: "pmr-liste-recap",
  props: {
     data : {type:Array,default:[]},
  },
  components: {PmrMateriel},
  setup(props, context) {
    

    const {getVilleDescription} = useEntGeos();
     const {getBandeDescription } = useBande('pmr')


    return {
      getVilleDescription,
      getBandeDescription
    };
  }
});
