

interface MaterielEntry {
  typeMateriel:{
    id?:string;
    nom?: string;
  }
  quantite:number
}
interface Line{
   id?:string;
   bande:{
    id:string
    libelle:string,
    debut:number|undefined;
    fin:number|undefined;
   };
   frequenceTx:number|undefined;
   frequenceRx:number|undefined;
   site : {
         code: string;
  nom: string;
  longitude: string;
  latitude: string;
  entGeo : {
    id:string;
  };
   };
   materiels : Array<MaterielEntry>
}
import { defineComponent, computed,onBeforeMount,ref} from "vue";
import { useStore } from "vuex";
import { map, flatten} from "lodash";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "pmr-matierel",
  props: {
     data : {type:Array, default: []},
  },
  components: {
  },
  setup(props) {


    const store   = useStore();

    onBeforeMount(() => {
        store .dispatch(Actions.GET_TYPE_MATERIEL)
    })
    
    // Ligne
    const materielsFromData = computed(() => 
    {
     return flatten(map(props.data,'materiels'));
    });

    // Retourne la liste des types de matériels disponibles
    const typeMateriels = computed(() => 
    {
        // on retourne le tout
        var materiels : Array<MaterielEntry> = []
        // On parcours tous les types de materiels du store
        store.getters.typeMateriels.forEach(item => {
            
            var obj :  MaterielEntry = {
            typeMateriel:{
                id : item.id,
                nom : item.nom
            },
            quantite:0
            }
              
            materielsFromData.value.forEach(m => {
            
               if(m.typeMateriel.id === obj.typeMateriel.id )
               {
                 obj.quantite += Number(m.quantite)
               }
               
            })
            // On ajoute notre objet modifié
            materiels.push(obj);
        });

 
        return materiels
    });

    return {
      typeMateriels
    };
  }
});
